import React from "react"

import Profile1 from '../../components/profile'



const Profiler = () => {
 
  return (
  <Profile1 /> 
  )
  
}

export default Profiler
